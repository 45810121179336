import { Anchor, Notification, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export enum ErrorNotificationType {
  NoService = 'no-service',
  SystemError = 'system-error',
  StateNotFound = 'state-not-found',
  LoginAlreadyCompleted = 'login-already-completed',
  PaymentCancelled = 'payment-cancelled',
  LoginNotFound = 'login-not-found',
  TerminalNotFound = 'terminal-not-found',
  ProductNotFound = 'product-not-found',
  InvalidInput = 'invalid-input',
}

export const isValidErrorNotificationType = (value?: string): value is ErrorNotificationType => {
  const notificationTypes: string[] = Object.values(ErrorNotificationType);
  return notificationTypes.includes(value ?? '');
};

export const ErrorNotification = ({ type, closeButton = false }: { type: ErrorNotificationType; closeButton?: boolean }) => {
  const {
    other: { spacing },
  } = useMantineTheme();
  const { t } = useTranslation();

  const errorText = useMemo(() => {
    switch (type) {
      case ErrorNotificationType.NoService:
        return (
          <>
            <Text size="sm" weight={600}>
              {t('error_coverage1')}
            </Text>
            <Text size="sm" weight={400}>
              {t('error_coverage2')}
            </Text>
          </>
        );
      case ErrorNotificationType.StateNotFound:
        return (
          <>
            <Text size="sm" weight={600}>
              {t('error_state1')}
            </Text>
            <Text size="sm" weight={400}>
              {t('error_state2')}
            </Text>
          </>
        );
      case ErrorNotificationType.PaymentCancelled:
        return (
          <>
            <Text size="sm" weight={600}>
              {t('error_payment1')}
            </Text>
            <Text size="sm" weight={400}>
              {t('error_payment2')}
            </Text>
          </>
        );
      case ErrorNotificationType.LoginAlreadyCompleted:
        return (
          <>
            <Text size="sm" weight={600}>
              {t('error_login1')}
            </Text>
            <Text size="sm" weight={400}>
              <Trans
                t={t}
                i18nKey="error_login2"
                components={{
                  a: <Anchor color="orange" size="sm" className="" href="http://tenants.carrot.tech/" aria-label="Back to login" />,
                }}
              />
            </Text>
          </>
        );
      case ErrorNotificationType.TerminalNotFound:
        return (
          <>
            <Text size="sm" weight={600}>
              {t('error_terminal1')}
            </Text>
            <Text size="sm" weight={400}>
              {t('error_terminal2')}
            </Text>
          </>
        );
      case ErrorNotificationType.ProductNotFound:
        return (
          <>
            <Text size="sm" weight={600}>
              {t('error_no_price1')}
            </Text>
            <Text size="sm" weight={400}>
              {t('error_no_price2')}
            </Text>
          </>
        );
      case ErrorNotificationType.SystemError:
      default:
        console.error(`Unknown error type: ${type}`);
        return (
          <>
            <Text size="sm" weight={600}>
              {t('error_system1')}
            </Text>
            <Text size="sm" weight={400}>
              {t('error_system2')}
            </Text>
          </>
        );
    }
  }, [type, t]);

  return (
    <div style={{ userSelect: 'text' }}>
      <Notification color="error" disallowClose={!closeButton} title={<Stack spacing={spacing(1)}>{errorText}</Stack>} />
    </div>
  );
};
